<template>
<div>
    <v-dialog v-model="$store.state.dialog2" width="400">
        <v-card flat class="card">
            <v-toolbar outlined flat>
                <v-toolbar-title>تغيير المخزن</v-toolbar-title>
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form @submit.prevent="changeWarehouse()" ref="form" v-model="valid">
                <!-- <pre dir="ltr">
                    {{ item }}
                    </pre> -->
                <v-container fluid>
                    <v-card outlined>
                        <v-container fluid>

                            <v-row>
                                <v-autocomplete v-model="item.warehouseId" :items="warehouses" label="المخزن" filled outlined :loading="$global.state.loading" item-text="name" item-value="id">
                                    <template v-slot:item="data">
                                        <template>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                                                <v-list-item-title>
                                                    <span class="teal--text" v-if="data.item.userType == 'delivery'">(مندوب تسليم)</span>
                                                    <span class="orange--text" v-if="data.item.userType == 'receiving'">(مندوب استلام)</span>
                                                </v-list-item-title>
                                                <v-divider class="mt-4"/>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                                <!-- <aAutoComplete  :fieldTitle="true" :items="warehouses" item-text="name" item-value="id" title="المخزن" :cols="12" :md="12" :required="true" /> -->
                            </v-row>

                        </v-container>
                    </v-card>
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn type="changeWarehouse" :disabled="!valid" :loading="loading" x-large color="primary" class="btn">حفظ</v-btn>
                            <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>إلغاء</v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-form>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {

    data() {
        return {
            item: {},
            valid: false,
            loading: false,
            warehouses: []
        }
    },

    created() {

        this.reset()
        this.getWarehouses()
        this.$eventBus.$on('fill-fields', () => {
            this.item = this.$store.state.itemDetails
        })

    },

    methods: {

        async changeWarehouse() {
            this.loading = true
            try {
                await this.$http.put(`Order/ChangeWarehouse?OrderId=${this.$store.state.itemDetails.id}&NewWarehouseId=${this.item.warehouseId}`)
                this.$service.success('تم الإضافة بنجاح')
                this.$eventBus.$emit(`${this.$route.path}`)
                this.close()
            } catch (err) {
                this.$service.faild('حدث خطأ')
                this.$service.faild(err.data.message)
                this.$service.faild(JSON.ify(err.data.result))
            } finally {
                this.loading = false
            }
        },

        async getWarehouses() {
            this.loading = true
            try {
                let res = await this.$http.get(`Warehouse?PageIndex=1&PageSize=1000`)
                this.warehouses = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        reset() {
            this.item = {
                "warehouseId": ""
            }
        },

        close() {
            this.$store.commit('setDialog2')
            this.reset()
            this.resetValidation()
            this.$store.commit('setItemDetails', {})
        },

        resetValidation() {
            this.$refs.form.resetValidation()
        },

    },
}
</script>
